<!--
 * @Author: your name
 * @Date: 2020-11-16 15:48:57
 * @LastEditTime: 2020-11-19 18:32:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\productionManagement\logisticsMonitoring\billInfo.vue
-->
<template>
    <div class="billInfo-wrapper">
        <table class="gridtable ">
            <tbody>
                <tr>
                    <td class="tit">
                        发货单编号
                    </td>
                    <td>{{ info.pcb_number }}</td>
                    <td class="tit">
                        送货车号
                    </td>
                    <td>{{ info.shch }}</td>
                </tr>
                <tr>
                    <td class="tit">
                        司机姓名
                    </td>
                    <td>{{ info.sjxm }}</td>
                    <td class="tit">
                        毛重
                    </td>
                    <td>{{ info.gross_weight }}吨</td>
                </tr>
                <tr>
                    <td class="tit">
                        皮重
                    </td>
                    <td>{{ info.tare_weight }}吨</td>
                    <td class="tit">
                        进场时间
                    </td>
                    <td>{{ info.into_time }}</td>
                </tr>
                <tr>
                    <td class="tit">
                        出厂时间
                    </td>
                    <td>{{ info.out_time }}</td>
                    <td class="tit">
                        到达时间
                    </td>
                    <td>{{ info.arrival_time }}</td>
                </tr>
                <tr>
                    <td class="tit">
                        开始卸料时间
                    </td>
                    <td>{{ info.unload_time }}</td>
                    <td class="tit">
                        结束卸料时间
                    </td>
                    <td>{{ info.unload_over_time }}</td>
                </tr>
                <tr>
                    <td class="tit">
                        签收方量
                    </td>
                    <td>{{ info.sign_volume }}方</td>
                    <td class="tit">
                        卸货方量
                    </td>
                    <td>{{ info.unload_volume }}方</td>
                </tr>
                <tr>
                    <td class="tit">
                        过磅方量
                    </td>
                    <td>{{ info.weigh_volume }}方</td>
                    <td class="tit"></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: { type: Object },
    },
    data() {
        return {
            pcb_number: '',
            info: {},
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.pcb_number = this.extr.pcb_number;
        this.handleInfo();
    },
    methods: {
        // 处理发货单详情
        async handleInfo() {
            const res = await this.getInfo();
            if (res) {
                this.info = res;
            }
        },
        // 获取发货单详情
        getInfo() {
            const path = '/interfaceApi/production/mapmanager/get_pcb_info?pcb_number=' + this.pcb_number;
            return this.$axios.$get(path, { defEx: true });
        },
    },
};
</script>
<style lang="stylus">
.billInfo-wrapper
    padding .2rem
    .gridtable
        border-width 1px
        border-color #D7D7D7
        border-collapse collapse
        width 100%
        th,td
            border 1px solid #D7D7D7
            padding .15rem
        .tit
            background-color #F6F8FC
            color #002B94
</style>